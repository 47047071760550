.SectionForm .SectionForm-Row {
  padding-left: 88px;
  font-size: 28px;
  line-height: 68px;
  height: 68px;
  overflow: hidden;
  color: white;
}
.SectionForm .SectionForm-Row .SectionForm-Cal {
  display: inline-flex;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.title {
  width: 502px;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.selection {
  width: 130px;
  height: 67.5px;
  overflow: hidden;
  user-select: none;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.selection > .ant-checkbox-wrapper {
  transform: scale(1.8);
  transform-origin: left center;
  position: relative;
  top: -11px;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.selection > label > span:last-child {
  font-weight: 300;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.selection.selection-key-1 > label > span:last-child {
  color: #ffff01;
}
.SectionForm .SectionForm-Row .SectionForm-Cal.selection.selection-key-2 > label > span:last-child {
  color: #e3f0f1;
}
