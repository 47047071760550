@keyframes RButtonHue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
.RButton:hover {
  background-image: unset !important;
}
.RButton > .hover {
  opacity: 0;
}
.RButton > .hover:hover {
  opacity: 1;
}
